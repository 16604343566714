import React, { useState, useEffect } from 'react'
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Container, Button, Header, Grid, Image } from 'semantic-ui-react'
import ProductCard from '../components/ProductCard';
//import * as LottiePlayer from "@lottiefiles/lottie-player";

import success from '../assets/success.svg';
import nyc from '../assets/nyc.jpg';
import va from '../assets/va.jpg';
import '../styles/home.css';

const products = [
    {
        name: 'New York ISP (Comcast)',
        description: ["Unlimited Bandwidth", "10Gbps Port", "Located in New York", "IP Authentication", "Instant Delivery"],
        duration: 'Weekly',
        image: nyc
    },
    {
        name: 'New York ISP (Comcast)',
        description: ["Unlimited Bandwidth", "10Gbps Port", "Located in New York", "IP Authentication", "Instant Delivery"],
        duration: 'Monthly',
        image: nyc
    },
    {
        name: 'New York ISP (Comcast)',
        description: ["Unlimited Bandwidth", "10Gbps Port", "Located in New York", "IP Authentication", "Instant Delivery"],
        duration: 'Bi-Monthly',
        image: nyc
    },
    {
        name: 'New York ISP (Comcast)',
        description: ["Unlimited Bandwidth", "10Gbps Port", "Located in New York", "IP Authentication", "Instant Delivery"],
        duration: 'Tri-Monthly',
        image: nyc
    }
];

export default function Home () {

    return (
        <div class="overflow-hidden mt-9 text-center lg:text-left">
            <div id="hero">
                <div class="p-3 self-center lg:mt-20">
                    <h1 class="font-sans text-primaryText font-extrabold text-5xl lg:text-7xl pt-6">Start winning on release day with the fastest, most reliable proxy solution.</h1>
                    <p class="text-onHoverPrimaryText font-medium text-2xl mt-7 lg:text-3xl lg:mt-10 mb-10">Flexible, premium ISP proxy packages that take your game to a new level.</p>
                    <div class="inline-flex rounded-md mt-4">
                        <a href="/pricing" class="transform hover:opacity-70 hover:-translate-y-1 duration-500 hover:text-primaryText shadow-md text-shadow inline-flex items-center justify-center m-3 h-12 px-3 py-3 lg:h-auto lg:px-5 lg:py-3 border-transparent lg:text-2xl font-semibold rounded-md text-primaryText bg-gradient-to-r from-primary to-secondary">
                        Proxy plans
                        </a>
                    </div>
                    <div class="inline-flex rounded-md mt-4 lg:ml-8">
                        <a href="https://discord.gg/h8fTByA" class="transform hover:opacity-70 hover:-translate-y-1 duration-500 hover:text-onHoverPrimaryText shadow-md text-shadow inline-flex items-center justify-center m-3 h-12 px-3 py-3 lg:h-auto lg:px-5 lg:py-3 border-transparent lg:text-2xl font-semibold rounded-md text-onHoverPrimaryText  bg-white hover:bg-indigo-50">
                        Join our Discord <img class="ml-4 w-8" width="30" src="https://blog.logomyway.com/wp-content/uploads/2020/12/discord-mascot.png" />
                        </a>
                    </div>
                </div>
                <div class="justify-self-center p-3">
                    <img width="900px"/>
                </div>
            </div>
            <div id="features" class="mt-20">
                <h1 class="font-sans text-primaryText font-semibold text-5xl lg:text-6xl text-center lg:mb-20">Features</h1>
                <div class="grid grid-cols-1 lg:space-y-10 md:space-y-0 md:grid md:grid-cols-4 md:gap-x-8 md:gap-y-10 overflow-hidden text-center">
                    <div class="lg:p-3 mt-10 lg:mt-0 self-center order-2 md:order-1 justify-self-center">
                        <h1 class="text-primaryText font-sans text-2xl lg:text-3xl">Instant Delivery</h1>
                        <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets7.lottiefiles.com/packages/lf20_amdrkqvo.json"
                            //style={{height: "300px", width: "300px"}}
                            class="w-3/4 lg:w-full lg:h-2xl mr-auto ml-auto"
                        >
                        </lottie-player>
                    </div>
                    <div class="p-3 self-center order-2 md:order-2 justify-self-center">
                        <h1 class="text-primaryText font-sans text-2xl lg:text-3xl">IP Authentication</h1>
                        <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets4.lottiefiles.com/packages/lf20_3kssikgz.json"
                            //style={{height: "300px", width: "300px"}}
                            class="w-3/4 lg:w-full lg:h-2xl mr-auto ml-auto"
                        >
                        </lottie-player>
                    </div>
                    <div class="p-3 self-center order-2 md:order-3 justify-self-center">
                        <h1 class="text-primaryText font-sans text-2xl lg:text-3xl">10 Gbps Port</h1>
                        <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets7.lottiefiles.com/packages/lf20_rrlmxjcn.json"
                            //style={{height: "300px", width: "300px"}}
                            class="w-3/4 lg:w-full lg:h-2xl mr-auto ml-auto"
                        >
                        </lottie-player>
                    </div>
                    <div class="p-3 self-center order-2 md:order-4 justify-self-center">
                        <h1 class="text-primaryText font-sans text-2xl lg:text-3xl">Unbanned</h1>
                        <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets9.lottiefiles.com/packages/lf20_7q1juibd.json"
                            //style={{height: "300px", width: "300px"}}
                            class="w-3/4 lg:w-full lg:h-2xl mr-auto ml-auto mt-5 lg:mt-0"
                        >
                        </lottie-player>
                    </div>
                </div>
            </div>
            <div id="pricing" class="mt-20">
                <h1 class="text-primaryText font-sans font-semibold text-5xl lg:text-6xl text-center mb-10 lg:mb-20">Pricing</h1>

                <div class="grid grid-cols-1 space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10 overflow-hidden">
                    <div class="p-3 self-center order-2 md:order-1">
                        {/* <h1 class="font-sans text-2xl lg:text-3xl text-center mb-20">ISP Proxy Plans</h1> */}
                        <div class={`grid grid-cols-1 max-w-md mr-auto ml-auto lg:max-w-full lg:grid-cols-${products.length}`}>
                            {
                                products.map(product => <ProductCard image={product.image} name={product.name} description={product.description} duration={product.duration} />)
                            }
                        </div>
                        
                    </div>
                    {/* <div class="justify-self-center p-3 md:order-4">
                        <h1 class="font-sans text-2xl lg:text-3xl text-center">Windows Servers</h1>
                    </div> */}
                </div>
            </div>
            <div id="success" class="mt-20">
                <h1 class="text-primaryText font-sans font-semibold text-5xl lg:text-6xl text-center lg:mb-20">Success</h1>

                <div class="grid grid-cols-1 space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 overflow-hidden">
                    <div class="p-3 self-center order-2 md:order-1">
                        <a data-theme="dark" class="twitter-timeline" href="https://twitter.com/NanoSuccess?ref_src=twsrc%5Etfw" data-chrome="nofooter noborders transparent"  data-dnt="true" data-border-color="#57FE76" height="500">
                        Tweets by NanoSuccess
                        </a>
                    </div>
                    <div class="justify-self-center p-3 md:order-2">
                        <img class="w-3/4 ml-auto mr-auto lg:w-full" src={success}/>
                    </div>
                </div>
            </div>
            <footer class="footer relative pt-1 border-t-2 border-onHoverPrimaryText mt-20">
                <div class="container mx-auto px-6">

                    <div class="sm:flex sm:mt-8">
                        <div class="mt-8 sm:mt-0 sm:w-full sm:px-8 flex flex-col md:flex-row justify-between">
                            <div class="flex flex-col">
                                <span class="font-sans font-bold text-onHoverPrimaryText uppercase mb-2">Sitemap</span>
                                <span class="my-2"><a href="/" class="text-onHoverPrimaryText text-md hover:text-nanoGreen duration-300">Home</a></span>
                                <span class="my-2"><a href="#features" class="text-onHoverPrimaryText  text-md hover:text-nanoGreen duration-300">Features</a></span>
                                <span class="my-2"><a href="#pricing" class="text-onHoverPrimaryText  text-md hover:text-nanoGreen duration-300">Pricing</a></span>
                                <span class="my-2"><a href="#success" class="text-onHoverPrimaryText  text-md hover:text-nanoGreen duration-300">Success</a></span>
                                <span class="my-2"><a href={`${process.env.REACT_APP_FRONT_END}/dashboard`} class="text-onHoverPrimaryText  text-md hover:text-nanoGreen duration-300">Dashboard</a></span>
                            </div>
                            <div class="flex flex-col">
                                <span class="font-sans font-bold text-onHoverPrimaryText uppercase mt-4 md:mt-0 mb-2 duration-300">Social Media</span>
                                <span class="my-2"><a href="https://discord.gg/h8fTByA" class="text-onHoverPrimaryText text-md hover:text-nanoGreen duration-300">Discord</a></span>
                                <span class="my-2"><a href="https://twitter.com/NanoProxies" class="text-onHoverPrimaryText  text-md hover:text-nanoGreen duration-300">Twitter</a></span>
                            </div>
                            <div class="flex flex-col">
                                <span class="font-sans font-bold text-onHoverPrimaryText uppercase mt-4 md:mt-0 mb-2 duration-300">Contact</span>
                                <span class="my-2"><a href="mailto:support@nanoproxies.io" class="text-onHoverPrimaryText  text-md hover:text-nanoGreen duration-300">Email</a></span>
                                <span class="my-2"><a href="https://discord.gg/h8fTByA" class="text-onHoverPrimaryText  text-md hover:text-nanoGreen duration-300">Discord</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container mx-auto px-6">
                    <div class="mt-16 border-t-2 border-onHoverPrimaryText flex flex-col items-center">
                        <div class="sm:w-2/3 text-center py-6">
                            <p class="font-sans text-sm text-nanoGreen font-bold mb-2">
                                © 2022 Nano Solutions, LLC
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};