import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Button} from 'semantic-ui-react';
import { createCheckoutSession } from '../../api/payment';
import { notify } from '../../utils/notification';
import { getDatacenterStock, isExactStockAvailable } from '../../api/proxies';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function DatacenterCheckout(props) {

    const handleClick = async () => {

        if (!props.status.user) return notify('danger', "Error", "Please sign-in first.");

        const stockAvailable = await getDatacenterStock()
            .then(stock => {
                const inStock = isExactStockAvailable(stock, props.servername, props.numOfProxies);
                return inStock;
            });
            
        if (!stockAvailable) return notify('danger', "Error", "Not enough stock for this package.");

        var stripe = await stripePromise;
        
        createCheckoutSession(props.priceId, window.location.href, props.numOfProxies)
            .then(data => {
                stripe.redirectToCheckout({sessionId: data.sessionId})
                    .then(handleResult);
            })
    };

    const handleResult = (result) => {
        if (result.error) {
          return notify('danger', "Error", result.error);
        }
    };
    
    return (
        <Button className="hover:opacity-70 focus:outline-none duration-300" fluid id="checkout" onClick={handleClick} active={props.available} disabled={!props.available}>{props.available ? 'Subscribe' : 'Sold Out'}</Button>
        //<a id="checkout" onClick={handleClick} active={props.available} disabled={!props.available} class="text-lg text-shadow bg-gradient-to-r from-primary to-secondary max-w-full rounded-md p-2 inline-flex items-center justify-center text-primaryText hover:text-primaryText hover:opacity-70 focus:outline-none duration-300">{props.available ? 'Subscribe' : 'Sold Out'}</a>
    )
};

const mapStateToProps = state => {
    return { status: state.status }
};

export default connect(mapStateToProps)(DatacenterCheckout);
