import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGa from 'react-ga';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Faq from './pages/Faq';
//import Header from './components/Header';
import Nav from './components/Nav';
import Dropdown from './components/Dropdown';
import ProxyPortal from './pages/ProxyPortal';
import Logout from './pages/Logout';
import Login from './pages/Login';
import AdminPanel from './pages/admin/AdminPanel';
import DashboardNav from './components/DashboardNav';
import ConfirmSignUp from './pages/ConfirmSignUp';
import AdminPanelNav from './components/admin/AdminPanelNav';
import Users from './pages/admin/Users';
import Proxies from './pages/admin/Proxies';
import Settings from './pages/admin/Settings';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import Success from './pages/Success';

import { checkAuth } from './api/auth';
import { setUserData } from "./store/action/action";

function App(props) {
  const [ isOpen, setIsOpen ] = useState(false);
  const noAuthRoutes = ['/login', '/logout', '/register', '/reset-password/', '/reset-password', '/register/confirmation/', '/pricing', '/'];
  
  useEffect(()=> {
    const isNoAuthRoute = noAuthRoutes.includes(window.location.pathname);
  
    if (!isNoAuthRoute) {
      checkAuth().then(response => response.success ? props.setUserData(response.success.user) : null)
    }
    
    if (process.env.NODE_ENV === 'production') {
      console.log('Running production.');
      ReactGa.initialize('UA-164639851-1');
      ReactGa.pageview(window.location.pathname + window.location.search);
    } else if (process.env.NODE_ENV === 'development') {
      console.log('Running local env.');
    } else {
      console.log('Not sure what env were running here')
    }
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <div className="theme-dark max-w-screen-lg xl:max-w-screen-xl mx-auto overflow-visible">
      <Router>
      <ReactNotification className="theme-dark max-w-screen-lg xl:max-w-screen-xl mx-auto overflow-visible" />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route path="/admin" component={AdminDashboard} />
      <Route path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/order/success" component={Success} />
          <Nav toggle={toggle} />
          <Dropdown isOpen={isOpen} toggle={toggle} />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/logout" component={Logout} />
              {/* <Route path="/orders" component={Orders} /> */}
              <Route exact path="/pricing" component={Pricing} />
              <Route path="/contact" component={Contact} />
              <Route path="/terms-and-conditions" component={TermsAndConditions} />
              <Route path="/privacy-policy" component={PrivacyPolicy}/>
              <Route path="/faq" component={Faq} />
              <Route path="/register/confirmation" component={ConfirmSignUp} />
              {/* <Route component={() => "404 NOT FOUND"} />  */}
            </Switch>
        </Router>
    </div> 
  )
};

const Dashboard = (props) => {

  return (
    <div>
      {/* <DashboardNav /> */}
      <Switch>
        <Route path="/dashboard" component={ProxyPortal} />
      </Switch>
    </div>
  )
};

const AdminDashboard = (props) => {
  return (
    <div>
      <AdminPanelNav />
      <Switch>
        <Route exact path="/admin" component={AdminPanel} />
        <Route path="/admin/users" component={Users} />
        <Route path="/admin/proxies" component={Proxies} />
        <Route path="/admin/settings" component={Settings} />
      </Switch>
    </div>
  )
};

const mapStateToProps = state => {
  return { status: state.status }
};

const mapDispatchToProps = dispatch => {
  return {
      setUserData: (user) => dispatch(setUserData(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
