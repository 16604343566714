import React, { useState, useEffect } from 'react';
import { getProxySettings, saveProxySettings } from '../api/proxies';
import { notify } from '../utils/notification';

export default function Settings() {
    const [ips, setIps] = useState([]);
    const [newIp, setNewIp] = useState('');
    const [loading, setLoading] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);

    useEffect(()=> {
        setLoading(true);

        getProxySettings().then(data => {
            setLoading(false)
            setIps(data.success)
        });
        
    }, []);

    const onKeyUp = (event) => {
    
        if (event.charCode === 13) {
            let validIpv4 = newIp.match(/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g);

            if (!validIpv4) {
                return notify('danger', "Error", 'Please insert a valid IPv4 address.');
            };

            if (ips.length >= 5) {
                return notify('danger', "Error", 'Maximum amount of whitelisted IPs allowed reached.')
            };

            const ipAlreadyExist = ips.filter(ip => ip === newIp);

            if (ipAlreadyExist.length !== 0) {
                return notify('danger', "Error", 'IP already whitelisted.');
            };

            setIps([...ips, newIp]);
            document.getElementById('newIp').value = '';
            setNewIp('')
        }
    };

    const removeIp = (e) => {
        console.log(e.target.id)
        let newIps = ips.filter(ip => ip !== e.target.id);
        setIps(newIps);
        return;
    };

    const saveIps = async () => {
        
        console.log(saveDisabled)
        setSaveDisabled(true);
        if (newIp.length !== 0) return notify('danger', "Error", 'No new IPs to save. Click enter after typing IP address');
        setSaveDisabled(false);

        saveProxySettings(ips).then(data => {
            notify('success', 'Saved', 'Authenticated IPs saved.')
            setIps(data.success)
        });
    };

    return (
        <div className="text-white text-center w-full pt-40 font-sans">
            <div className="m-auto min-w-3xl w-6/7 lg:max-w-4xl bg-onPrimaryBg h-4xl pt-10 rounded-md">
                <table className="w-full">
                <thead>
                    <tr>
                        <th className="float-left px-14 font-semibold">IP Address</th>
                    </tr>
                    <tr className="block border-b-2 border-onPrimaryBgSofter ml-10 mr-10 mt-5 mb-5 w-auto"></tr>
                </thead>
                <tbody id="proxyList" className="bg-onPrimaryBg scrollbar-thin scrollbar-track-onPrimaryBg scrollbar-thumb-onPrimaryBgSofter hover:scrollbar-thumb-onPrimaryBgSofter">
                    {
                        ips.map(e => {
                            return (
                                <tr className="animate-fade-in-down" key={e}>
                                    <td className="float-left px-14  text-nanoGreen">{e}</td>
                                    <td className="select-none float-right px-16 text-onPrimaryBgSofter">
                                        <svg id={e} onClick={removeIp} className="w-7 text-onPrimaryBgSofter hover:text-primaryText cursor-pointer mb-auto transform hover:scale-110 duration-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path className="pointer-events-none" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr className={`animate-fade-in-down ${ips.length >= 5 ? 'hidden' : ''}`}>
                        <td className="float-left px-14">
                            <input 
                                autoComplete="off"
                                id="newIp"
                                onKeyPress={onKeyUp} 
                                ref={input => input && input.focus()} 
                                className="bg-onPrimaryBgSoft text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter" 
                                placeholder="New IPv4 Address" 
                                onChange={(e)=> setNewIp(e.target.value)}
                            />
                        </td>
                    </tr>
                </tbody>
                </table>
                <div className="flex justify-between pt-12">
                <button onClick={()=> setIps([])} className="bg-onPrimaryBg p-3 rounded-md text-lg font-medium w-40 focus:outline-none outline-none transform hover:opacity-60 duration-300">
                    Clear
                </button>
                <button disabled={saveDisabled} onClick={saveIps} className={`bg-onPrimaryBg p-3 rounded-md text-lg font-medium w-40 focus:outline-none outline-none transform hover:opacity-60 duration-300 disabled:opacity-10}`}>
                    Save
                </button>
                </div>
            </div>
        </div>
    )
};
