import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter, Redirect, useLocation } from 'react-router-dom';
import ClipboardJS from "clipboard";
import { notify } from '../utils/notification';
import { createCustomerPortalSession } from '../api/payment';

import './Scrollbar.css';
import logo from '../assets/nano-logo.png';



function Sidebar(props) {
    const [ active, setActive ] = useState('');
    const [redirect, setRedirect] = useState(false);

    function useQuery() {
      return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

    useEffect(()=> {
      console.log(window.location.search.includes('settings'))
        if (props.packages.length > 0 && !window.location.search.includes('settings')) setRedirect(true)
        const ispClipboard = new ClipboardJS(".copyIspList");
        const datacenterClipboard = new ClipboardJS(".copyDatacenterList");

        ispClipboard.on('success', function (e) {
          notify('info', 'Copied', 'ISP Proxy lists successfully copied.')
          return e.clearSelection();
        });

        datacenterClipboard.on('success', function (e) {
            notify('info', 'Copied', 'Datacenter Proxy lists successfully copied.')
            return e.clearSelection();
          });
        return function cleanup(){ 
            ispClipboard.destroy();
            datacenterClipboard.destroy();
        }
      }, [props.packages]);

    const handleClick = (e) => {
        return setActive(e.target.textContent);
    };

    return (
        <div className="bg-onPrimaryBg fixed px-2 text-center max-w-md left-0 h-full w-full pt-8 pb-8 mb-8">
          {redirect ? <Redirect push to={`/dashboard/?package=${props.packages[0].authToken}`} /> : null}
            <div className="relative float-left px-6 w-full h-full">
                <div className="flex justify-evenly border-b-2 border-onPrimaryBgSofter py-4 mb-12">
                    <img className="h-xl cursor-pointer m-0" onClick={()=> window.location = `${process.env.REACT_APP_FRONT_END}`} src={logo} />
                    <h1 className="font-sans font-semibold text-2xl self-center">Nano Proxies</h1>
                </div>
               <ul className="flex-col text-left h-full">
                  <div className="mb-3">
                    <li className="flex justify-between border-b-2 border-onPrimaryBgSofter">
                            <a className="inline-block py-5 text-primaryText font-sans font-bold text-xl no-underline hover:text-primaryText">
                                ISP
                            </a>
                            <svg data-clipboard-text={fullProxyList('isp', props.packages)} className="copyIspList w-8 text-onPrimaryBgSofter hover:text-primaryText cursor-pointer mt-auto mb-auto transform hover:scale-110 duration-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                            </svg>
                    </li>
                  </div>
                  <div className="proxyTokens mb-3 max-h-2xl scrollbar-thin scrollbar-track-onPrimaryBg scrollbar-thumb-onPrimaryBgSofter hover:scrollbar-thumb-onPrimaryBgSofter">
                    {
                        props.packages.length === 0 ? null : 
                            props.packages.map(pack => {
                                if (!pack.hasOwnProperty('category')) return;
                                if (pack.category !== 'isp') return;
                                return (
                                    <li className="py-3 font-sans animate-fade-in-down" key={pack.authToken}>
                                        <Link to={`/dashboard/?package=${pack.authToken}`} onClick={handleClick} className={`${active === pack.authToken || window.location.search.includes(pack.authToken) ? 'text-nanoGreen' : 'text-onPrimaryBgSofter'} font-semibold text-lg cursor-pointer m-0 hover:text-nanoGreen focus:outline-none`}>{pack.authToken}</Link>
                                    </li>
                                )
                            })
                    }
                  </div>
                  <div className="mb-3">
                    <li className="flex justify-between border-b-2 border-onPrimaryBgSofter">
                            <a className="inline-block py-5 text-primaryText font-sans font-bold text-xl no-underline hover:text-primaryText">
                                Datacenter
                            </a>
                            <svg data-clipboard-text={fullProxyList('datacenter', props.packages)} className="copyDatacenterList w-8 text-onPrimaryBgSofter hover:text-primaryText cursor-pointer mt-auto mb-auto transform hover:scale-110 duration-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                            </svg>
                    </li>
                  </div>
                  <div className="proxyTokens mb-3 max-h-2xl scrollbar-thin scrollbar-track-onPrimaryBg scrollbar-thumb-onPrimaryBgSofter hover:scrollbar-thumb-onPrimaryBgSofter">
                    {
                        props.packages.length === 0 ? null : 
                            props.packages.map(pack => {
                                if (!pack.hasOwnProperty('category')) return;
                                if (pack.category !== 'datacenter') return;
                                return (
                                    <li className="py-3 font-sans animate-fade-in-down" key={pack.authToken}>
                                        <Link to={`/dashboard/?package=${pack.authToken}`} onClick={handleClick} className={`${active === pack.authToken || window.location.search.includes(pack.authToken) ? 'text-nanoGreen' : 'text-onPrimaryBgSofter'} font-semibold text-lg cursor-pointer m-0 hover:text-nanoGreen focus:outline-none`}>{pack.authToken}</Link>
                                    </li>
                                )
                            })
                    }
                  </div>
                  <div className="mb-3 mt-10">
                    <li className="flex justify-between border-b-2 border-onPrimaryBgSofter">
                        <a className="inline-block py-5 text-primaryText font-sans font-bold text-xl no-underline hover:text-primaryText">
                            Settings
                        </a>
                    </li>
                  </div>
                  <div className="mb-3">
                    {
                      props.packages.length === 0 ? null :
                      <li className="py-3 font-sans">
                        <NavLink to={`/dashboard/?settings`} onClick={handleClick} className={`${active === 'Authenticated IPs' || window.location.search.includes('settings') ? 'text-nanoGreen' : 'text-onPrimaryBgSofter'} font-semibold text-lg cursor-pointer m-0 hover:text-nanoGreen focus:outline-none`}>Authenticated IPs</NavLink>
                      </li>
                    }
                    <li className="py-3 font-sans">
                        <button onClick={createCustomerPortalSession} className={`${active === 'Billing' ? 'text-nanoGreen' : 'text-onPrimaryBgSofter'} font-semibold text-lg cursor-pointer m-0 hover:text-nanoGreen focus:outline-none`}>Billing</button>
                    </li>
                  </div>
                  <li className="absolute px-2 bottom-0 w-full max-w-md grid place-items-center left-0">
                    <div>
                      {props.status.user ? 
                        <h1 className="font-sans font-semibold text-2xl text-nanoGreen flex">
                          {props.status.user.email}
                          <svg onClick={()=> window.location = process.env.REACT_APP_FRONT_END + '/logout'} class="w-8 ml-8 text-onPrimaryBgSofter hover:text-error cursor-pointer mt-auto mb-auto transform hover:scale-110 duration-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
                          </svg>
                        </h1> 
                        : <a href={`${process.env.REACT_APP_FRONT_END}/login`} class="text-lg text-shadow login-button-portal bg-gradient-to-r from-primary to-secondary max-w-full rounded-md p-2 inline-flex items-center justify-center text-primaryText hover:text-onHoverPrimaryText hover:bg-gray-100 focus:outline-none">Login</a>
                      }
                    </div>
                  </li>
               </ul>
            </div>
         </div>
    )
};

const mapStateToProps = state => {
    return { status: state.status }
};

export default connect(mapStateToProps)(Sidebar);

function fullProxyList(category, proxyPacks) {
    
    let clipboardText = "";

    proxyPacks = proxyPacks.filter(pack => pack.category === category);

    proxyPacks.forEach((pack)=> {
      pack.proxies.forEach(proxy => {
        if (proxy == proxyPacks[proxyPacks.length - 1]) {
            clipboardText += proxy;
          } else {
            clipboardText += proxy + '\n';
          } 
      })                  
    });
    
    return clipboardText;
};