export const createCheckoutSession = async (priceId, previousPage, quantity) => {
    return fetch(process.env.REACT_APP_BACK_END + "/payment/create-checkout-session", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          priceId: priceId,
          previousPage: previousPage,
          quantity: quantity
        })
      }).then(function(result) {
        return result.json();
      });
};

export const createCustomerPortalSession = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const response = await fetch(process.env.REACT_APP_BACK_END + '/payment/create-customer-portal-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include'
    })
      .then(res => res.json())
      .catch(error => error);

    return window.location = response.redirect;
};

export const processPayment = async (route, token, product) => {

    const response = await fetch(process.env.REACT_APP_BACK_END + '/payment' + route, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            token,
            product
        })
      }).then(response => response.json());

    return response;
};

export const processCoupon = async (coupon, productId) => {

    const response = await fetch(process.env.REACT_APP_BACK_END + '/product/coupons/apply', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            coupon: coupon.toLowerCase(),
            productId: productId
        })
      }).then(response => response.json());

    return response;
};

